import React, { useEffect, useRef } from 'react';

const GoogleMaps: React.FC = () => {
  const mapRef = useRef<google.maps.Map | null>(null); // Reference to the map instance
  const markerRef = useRef<google.maps.Marker | null>(null); // Reference to the marker instance

  useEffect(() => {
    const locationCoordinates = { lat: 45.3471074, lng: -75.6250662 };

    const initializeMap = () => {
      const mapElement = document.getElementById('map');
      if (mapElement) {
        mapRef.current = new window.google.maps.Map(mapElement, {
          center: locationCoordinates,
          zoom: 15, // Zoom level adjusted for better visibility
          mapTypeControl: false, // Hide the map type control
          streetViewControl: false, // Hide the street view control
          fullscreenControl: false // Hide the fullscreen control
        });

        // Add a marker for the specified location
        markerRef.current = new window.google.maps.Marker({
          position: locationCoordinates,
          map: mapRef.current,
          title: "2950 Bank St, Ottawa"
        });
      } else {
        console.error("Map element not found.");
      }
    };

    // Load Google Maps API asynchronously
    const loadGoogleMaps = () => {
      const googleMapsScript = document.createElement('script');
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBGSsfSMVPeeT92fKJmB6rWwFHMdheC0Vc&callback=initMap`;
      googleMapsScript.async = true;
      googleMapsScript.defer = true; // Ensure script executes after DOM has loaded
      document.body.appendChild(googleMapsScript);
    };

    // Ensure initMap function is accessible globally
    (window as any).initMap = initializeMap;

    // Load Google Maps API
    loadGoogleMaps();

    // Cleanup function to remove the Google Maps script
    return () => {
      const googleMapsScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
      if (googleMapsScript) {
        document.body.removeChild(googleMapsScript);
        delete (window as any).initMap;
      }
    };
  }, []);

  return <div id="map" style={{ width: '100%', height: '300px' }} />;
};

export default GoogleMaps;
