import React, { useEffect, useState, useContext } from "react";
import { CartContext } from '../../context/CartContext';
import taxRate from '../../config/CommerceSettings';
import axios from 'axios';
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { useHistory } from 'react-router-dom';

export default function CheckoutForm({ secretKey, full_name, customer_email, customer_postal_code, currentCart, totalPrice, buttonLoading, setButtonLoading }) {

    const history = useHistory();

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            secretKey
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const getOrderTime = () => {
        const currentTime = new Date();
        const orderTime = new Date(currentTime.getTime() + 20 * 60000); // 20 minutes in milliseconds
        return orderTime;
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: full_name,
                        email: customer_email,
                        address: {
                            country: "CA",
                            postal_code: customer_postal_code
                        }
                    }
                },
                // Make sure to change this to your payment completion page
                return_url: "http://192.168.0.14:3000",
            },
        });

        if (error) {
            setButtonLoading(false);
            setMessage(error.message);
        } else {
            const transactionId = paymentIntent.id;
            axios.post(`https://angeliques-api.vervedev.com/api/checkout/complete`, { orderDetails: currentCart, customerName: full_name, orderTotal: totalPrice, transactionId: transactionId, tenantId: process.env.REACT_APP_TENANT_ID, restaurantId: 2 });
            const orderTime = getOrderTime();
            history.replace('/OrderConfirmation', { jsonParam: currentCart, orderTime: orderTime });
        }
    };

    const paymentElementOptions = {
        layout: "tabs",
        style: {
            base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
            },
        },
        fields: {
            billingDetails: {
                address: {
                    country: "never",
                    postalCode: "never"
                },
            }
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <PaymentElement id="payment-element" options={paymentElementOptions} />
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}