import { IonContent, IonFooter, IonGrid, IonRow, IonCol, useIonViewWillEnter, IonPage, IonIcon, IonButton, IonBackButton, IonButtons, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router";
import './css/ViewMenuItem.css';
import { cart } from 'ionicons/icons';
import { CartContext } from '../context/CartContext';
import axios from 'axios';

import { Capacitor } from '@capacitor/core';

import { StatusBar, Style } from '@capacitor/status-bar';

import leftArrow from '../assets/img/left-arrow.svg';

import { Haptics, ImpactStyle } from '@capacitor/haptics';


interface DefaultParams {
  id: string;
}

interface Option {
  option_id: number;
  option_name: string;
  option_price: number;
}

interface Choice {
  choice_id: number;
  choice_name: string;
  choice_price: number;
}

interface ChoiceGroup {
  choice_group_id: number;
  choice_group_name: string;
  choices: Choice[];
}

const ViewMenuItem: React.FC = () => {

  const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (event: { detail: { scrollTop: any; }; }) => {
    const scrollTop = event.detail.scrollTop;
    if (scrollTop > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const [isApplePhone, setApplePhone] = useState(false);

  useEffect(() => {
    async function checkIsIOS() {
      const info = Capacitor.getPlatform();
      if (info === 'ios') {
        setApplePhone(true);
      } else {
        setApplePhone(false);
      }
    }

    checkIsIOS();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedChoices, setSelectedChoices] = useState<{ [key: string]: string }>({});
  // Track selected options
  const [optionPrices, setOptionPrices] = useState<{ [key: string]: any }>({}); // Track option prices

  const handleOptionChange = (optionId: string, isChecked: boolean, optionPrice: any) => {
    if (isChecked) {
      setSelectedOptions([...selectedOptions, optionId]);
      setOptionPrices({ ...optionPrices, [optionId]: optionPrice });
    } else {
      setSelectedOptions(selectedOptions.filter(id => id !== optionId));
      const updatedOptionPrices = { ...optionPrices };
      delete updatedOptionPrices[optionId];
      setOptionPrices(updatedOptionPrices);
    }
  };

  /* Hide Tabbar Start */
  useIonViewWillEnter(() => {
    const tabBar = document.getElementById('app-tab-bar');
    if (tabBar != null) {
      tabBar.style.opacity = '0';
      tabBar.style.display = 'none';
    }
  });
  /* Hide Tabbar End */

  const { id } = useParams<DefaultParams>();

  var idInt = parseInt(id);

  const [currentCart, setCart] = useContext(CartContext);

  const addToCart = (
    itemName: string,
    itemId: string,
    itemPrice: number,
    itemCategory: string,
    itemCategoryName: string
  ) => {
    hapticsImpactLight();
    const cartCount = document.getElementById('cart-count');
    if (cartCount != null) {
      cartCount.style.transition = "0.05s";
      cartCount.style.transform = "scale(1.3)";
      setTimeout(function () {
        cartCount.style.transform = "scale(1)";
      }, 50);
    }

    const totalPrice = itemPrice + // Base price of the item
      selectedOptions.reduce((total, optionId) => total + optionPrices[optionId], 0) + // Total price of selected options
      Object.values(selectedChoices).reduce((total, choiceId) => { // Total price of selected choices
        const choice = choicesData.find(group => group.choices.some(choice => choice.choice_id.toString() === choiceId));
        if (choice) {
          const selectedChoice = choice.choices.find(choice => choice.choice_id.toString() === choiceId);
          if (selectedChoice) {
            return total + selectedChoice.choice_price;
          }
        }
        return total;
      }, 0);

    // Generate a unique ID based on item ID, selected options, and selected choices
    const uniqueId = `${itemId}_${selectedOptions.join('_')}_${Object.values(selectedChoices).join('_')}`;

    // Generate the choices array based on the currently selected choices
    const choices: { name: string; price: number }[] = [];
    choicesData.forEach((group) => {
      const selectedChoiceId = selectedChoices[group.choice_group_id.toString()];
      if (selectedChoiceId) {
        const selectedChoice = group.choices.find(choice => choice.choice_id.toString() === selectedChoiceId);
        if (selectedChoice) {
          choices.push({
            name: selectedChoice.choice_name,
            price: selectedChoice.choice_price,
          });
        }
      }
    });

    // Check if an item with identical options and choices already exists in the cart
    const existingItemIndex = currentCart.findIndex((item: any) => {
      if (item.id === uniqueId) {
        return true;
      }
      return false;
    });

    if (existingItemIndex !== -1) {
      // If item with identical options and choices already exists in cart, update its quantity
      const updatedCart = [...currentCart];
      updatedCart[existingItemIndex] = {
        ...updatedCart[existingItemIndex],
        qty: updatedCart[existingItemIndex].qty + 1,
      };
      setCart(updatedCart);
    } else {
      // If item doesn't exist in cart, add it with quantity 1 and total price
      const item = {
        name: itemName,
        id: uniqueId,
        category: itemCategory,
        categoryName: itemCategoryName,
        options: selectedOptions.map((optionId) => {
          const option = optionsData.find((option) => option.option_id.toString() === optionId);
          return option ? { id: option.option_id, name: option.option_name, price: option.option_price } : null;
        }),
        choices: choices,
        qty: 1,
        price: totalPrice, // Use the calculated total price
      };
      setCart([...currentCart, item]);
    }
  };


  const totalQuantity = currentCart.reduce((acc: any, curr: any) => acc + curr.qty, 0)

  /* Load Menu Items */

  interface MenuData {
    item_id: number;
    item_name: string;
    item_category_id: number;
    item_description: string;
    item_price: number;
    item_img_url: string;
  }

  const [menuData, setMenuData] = useState([]);

  const [itemId, setItemId] = useState(0);
  const [itemCategoryId, setItemCategoryId] = useState(0);
  const [itemCategoryName, setItemCategoryName] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemImgUrl, setItemImgUrl] = useState("");

  const [optionsData, setOptionsData] = useState<Option[]>([]);
  const [choicesData, setChoicesData] = useState<ChoiceGroup[]>([]);

  function loadMenuItem() {
    // Get User Variables
    axios.get(`https://angeliques-api.vervedev.com/api/menu/` + id)
      .then(res => {
        setItemCategoryName(res.data.data.item_category_name);
        setMenuData(res.data.data);
        setItemId(res.data.data.item_id);
        setItemName(res.data.data.item_name);
        setItemCategoryId(res.data.data.item_category_id);
        setItemDescription(res.data.data.item_description);
        setItemPrice(res.data.data.item_price);
        setItemImgUrl(res.data.data.item_img_url);
      });
  }

  function loadMenuItemOptions() {
    // Get User Variables
    axios.get(`https://angeliques-api.vervedev.com/api/menu/options/` + id)
      .then(res => {
        setOptionsData(res.data.data)
      });
  }

  function loadMenuItemChoices() {
    // Get User Variables
    axios.get(`https://angeliques-api.vervedev.com/api/menu/choices/` + id)
      .then(res => {
        setChoicesData(res.data.data)
      });
  }

  const renderOptions = () => {
    return (optionsData as Option[]).map((option) => (
      <div key={option.option_id} className="cart-option">
        <label>
          <IonRow className="ion-no-padding">
            <IonCol size="11" className="ion-no-padding">
              <h5>
                {option.option_name} {option.option_price != 0 ? "(+" + (option.option_price).toFixed(2) + ")" : null}
              </h5>
            </IonCol>
            <IonCol size="1" className="ion-no-padding align-right add-checkbox">
              <input
                type="checkbox"
                name={option.option_name}
                onChange={(e) =>
                  handleOptionChange(
                    option.option_id.toString(),
                    e.target.checked,
                    option.option_price
                  )
                }
              />
            </IonCol>
          </IonRow>
        </label>
      </div>
    ));
  };

  const handleChoiceChange = (groupId: number, choiceId: string) => {
    setSelectedChoices({ ...selectedChoices, [groupId.toString()]: choiceId });
  };

  const renderChoices = () => {
    return choicesData.map((group) => (
      <div key={group.choice_group_id}>
        <h2>{group.choice_group_name}</h2>
        {[...group.choices].reverse().map((choice) => (
          <div key={choice.choice_id} className="cart-option">
            <label>
              <IonRow className="ion-no-padding">
                <IonCol size="11" className="ion-no-padding">
                  <h5>
                    {choice.choice_name} {choice.choice_price != 0 ? "(+" + (choice.choice_price).toFixed(2) + ")" : null}
                  </h5>
                </IonCol>
                <IonCol size="1" className="ion-no-padding align-right add-radio">
                  <input
                    type="radio"
                    name={`group_${group.choice_group_id}`} // Use a unique name for each group
                    value={choice.choice_id.toString()} // Use the choice ID as the value
                    onChange={() => handleChoiceChange(group.choice_group_id, choice.choice_id.toString())} // Call handleChoiceChange when radio input is changed
                  />
                </IonCol>
              </IonRow>
            </label>
          </div>
        ))}
      </div>
    ));
  };



  useEffect(() => {
    loadMenuItem();
    loadMenuItemOptions();
    loadMenuItemChoices();
  }, []);


  return (
    <IonPage>
      <IonContent scrollY={true} scrollEvents={true} onIonScroll={handleScroll}>
        <div className={isApplePhone == true ? "ios-wrap" : ""}>
          <div className="menu-item-img-wrapper" style={{ background: "linear-gradient(to right, rgba(0,0,0,0.00) 100%, transparent 40%), url('" + itemImgUrl + "') no-repeat center center" }} >
            <div className="wrapper view-menu-item">
              <div className={isApplePhone == true ? "ios-wrapper" : "null"}>
              </div>
              <div className="top-bar">
                <div className={scrolled ? "fixed-top scrolled" : "fixed-top"}>
                  <IonGrid class="ion-no-padding">
                    <IonRow class="ion-no-padding">
                      <IonCol size="4" class="ion-no-padding">
                        <IonButton routerLink="/Tab1" routerDirection="back" class="back-button">
                          <img src={leftArrow} className="icon-img" />
                        </IonButton>
                      </IonCol>
                      <IonCol size="4">

                      </IonCol>
                      <IonCol size="4" class="right-align ion-no-padding" >
                        <IonButton routerLink="/CartView" class="cart-button">
                          <div className="cart-icon-wrapper">
                            <IonIcon icon={cart} className="cart-icon"></IonIcon>
                          </div>
                          <div className="cart-count" id="cart-count">
                            {totalQuantity}
                          </div>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </div>
          </div>
          <div className="viewing-item-wrapper wrapper">
            <h1>{itemName}</h1>
            <span className="styled-border-left">{itemCategoryName}</span>
            <p>{itemDescription}</p>
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-no-padding">
                <IonCol col-size="12" className="ion-no-padding">
                  {renderChoices()}
                  {optionsData.length > 0 ? <h2>Options</h2> : null}
                  {renderOptions()}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="add-to-cart">
            <button onClick={() => addToCart(itemName, itemId.toString(), itemPrice, itemCategoryId.toString(), itemCategoryName.toString())}>
              Add to Cart (<span className="dollar-sign">$</span>
              {/* Display the total price including selected options */}
              {(itemPrice +
                selectedOptions.reduce((total, optionId) => total + optionPrices[optionId], 0) +
                Object.values(selectedChoices).reduce((total, choiceId) => {
                  const choice = choicesData.find(group => group.choices.some(choice => choice.choice_id.toString() === choiceId));
                  if (choice) {
                    const selectedChoice = choice.choices.find(choice => choice.choice_id.toString() === choiceId);
                    if (selectedChoice) {
                      return total + selectedChoice.choice_price;
                    }
                  }
                  return total;
                }, 0)).toFixed(2)}
              )
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewMenuItem;
