import { IonContent, IonGrid, IonRow, IonCol, IonPage, IonIcon, IonButton, useIonViewWillEnter, IonBackButton, IonButtons, IonHeader, IonToolbar, IonTitle, IonFooter } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { cart, add, remove } from 'ionicons/icons';
import { useParams } from "react-router";
import './css/ViewMenuItem.css';
import './css/Cart.css';

import { CartContext } from '../context/CartContext';

import { Capacitor } from '@capacitor/core';

import taxRate from '../config/CommerceSettings';

import { StatusBar, Style } from '@capacitor/status-bar';

import leftArrow from '../assets/img/left-arrow.svg'; 

interface DefaultParams {
  id: string;
}

const CartView: React.FC = () => {

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };

  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
  };

  // setStatusBarStyleLight();

  const [isApplePhone, setApplePhone] = useState(false);

  useIonViewWillEnter(() => {
    const tabBar = document.getElementById('app-tab-bar');
    if (tabBar != null) {
      tabBar.style.opacity = '0';
      tabBar.style.display = 'none';
    }
  });

  useEffect(() => {
    async function checkIsIOS() {
      const info = Capacitor.getPlatform();
      if (info === 'ios') {
        setApplePhone(true);
      } else {
        setApplePhone(false);
      }
    }

    checkIsIOS();
  }, []);

  let history = useHistory();

  const goToPreviousPath = () => {
    history.goBack()
  }
  const [currentCart, setCart] = useContext(CartContext);

  const addItemToCart = (itemId: number) => {
    console.log(currentCart)
    const uniqueId = `${itemId}_${Date.now()}`;
    const item = { id: uniqueId };

    const updatedCart = currentCart.map((cartItem: any) =>
      cartItem.id === itemId ? { ...cartItem, qty: cartItem.qty + 1 } : cartItem
    );

    setCart(updatedCart);
    console.log(currentCart);
  };

  const removeItemFromCart = (itemId: number) => {
    const updatedCart = currentCart
      .map((cartItem: any) =>
        cartItem.id === itemId ? { ...cartItem, qty: cartItem.qty - 1 } : cartItem
      )
      .filter((cartItem: any) => cartItem.qty > 0);

    setCart(updatedCart);
  };

  const totalQuantity = currentCart.reduce((acc: any, curr: any) => acc + curr.qty, 0)

  const subTotalPrice = Number(currentCart.reduce((acc: any, curr: any) => acc + (curr.price * curr.qty), 0)).toFixed(2);

  const taxTotal = Number((Number(subTotalPrice) * taxRate)).toFixed(2);
  const taxTotalString = taxRate.toString().replace('0.', '');

  const totalPrice = Number(Number(subTotalPrice) + Number(taxTotal)).toFixed(2);



/* Setup Checkout */

  return (
    <IonPage>
      <IonContent>
        <div className={isApplePhone == true ? "ios-wrapper" : "null"}>
        </div>
        <div className="wrapper view-menu-item cart-wrapperr">
          <div className="top-bar">
            <IonGrid class="ion-no-padding">
              <IonRow class="ion-no-padding">
                <IonCol size="4" class="ion-no-padding">
                  <IonButton onClick={goToPreviousPath} class="back-button">
                  <img src={leftArrow} className="icon-img"/>
                  </IonButton>
                </IonCol>
                <IonCol size="4">
                  <h1 className="page-title">Your Cart</h1>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="cart-count-bar">
            <h2 className="cart-count-title"><IonIcon icon={cart} className="cart-view-icon"></IonIcon>You have {totalQuantity} item(s) in your cart</h2>
          </div>
          {currentCart.map((cartItems: { name: string; qty: number; id: number; price: number; category: string; categoryName: string; options: any; choices: any; }, idx: string | number | null | undefined) => (
            <div className="cart-item" key={idx}>
              <IonGrid class="ion-no-padding">
                <IonRow class="ion-no-padding">
                  <IonCol size="12" class="ion-no-padding">
                    <h4 className="cart-item-name">{cartItems.name}</h4>
                    <h6 className="styled-border-left">{cartItems.categoryName}</h6>
                    {/* <h5 className="cart-item-category">{cartItems.category}</h5> */}
                    <ul className="cart-item-options">
                      {cartItems.choices.map((choice: { name: string; price: number }, optionIdx: string | number) => (
                        <div key={optionIdx} className="cart-item-option">
                          <li>{choice.name} (+${choice.price})</li>
                        </div>
                      ))}
                    </ul>
                    <ul className="cart-item-options">
                      {cartItems.options.map((option: { name: string; price: number }, optionIdx: string | number) => (
                        <div key={optionIdx} className="cart-item-option">
                          <li>{option.name} (+${option.price})</li>
                        </div>
                      ))}
                    </ul>
                    <IonRow class="ion-no-padding">
                      <IonCol size="6" class="ion-no-padding">
                        <h6 className="cart-item-price"><span className="dollar-sign">$</span>{cartItems.price}</h6>
                      </IonCol>
                      <IonCol size="6" class="ion-no-padding">
                        <IonRow class="ion-no-padding">
                          <IonCol size="4" class="ion-no-padding right-align">
                            <button className="cart-item-increment-button" onClick={() => removeItemFromCart(cartItems.id)}><IonIcon icon={remove}></IonIcon></button>
                          </IonCol>
                          <IonCol size="4" class="ion-no-padding">
                            <h6 className="cart-item-qty">{cartItems.qty}</h6>
                          </IonCol>
                          <IonCol size="4" class="ion-no-padding">
                            <button className="cart-item-increment-button" onClick={() => addItemToCart(cartItems.id)}><IonIcon icon={add}></IonIcon></button>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          ))}
        </div>
        <div className="cart-footer footer-wrapper">
          <IonGrid class="ion-no-padding">
            <IonRow class="ion-no-padding footer-totals">
              <IonCol size="6" class="ion-no-padding">
                <h3 className="footer-subtotal">Item Subtotal</h3>
              </IonCol>
              <IonCol size="6" class="ion-no-padding">
                <h3 className="right-align footer-subtotal-price">$ {subTotalPrice}</h3>
              </IonCol>
            </IonRow>
            <IonRow class="ion-no-padding footer-totals">
              <IonCol size="6" class="ion-no-padding">
                <h3 className="footer-subtotal">Tax ({taxTotalString}%)</h3>
              </IonCol>
              <IonCol size="6" class="ion-no-padding">
                <h3 className="right-align footer-subtotal-price">$ {taxTotal}</h3>
              </IonCol>
            </IonRow>
            <IonRow class="ion-no-padding footer-totals footer-final-pice">
              <IonCol size="6" class="ion-no-padding">
                <h3 className="footer-subtotal">Total Price</h3>
              </IonCol>
              <IonCol size="6" class="ion-no-padding">
                <h3 className="right-align footer-subtotal-price">$ {totalPrice}</h3>
              </IonCol>
              <IonButton className="cart-checkout-button" routerLink="/Checkout" routerDirection="forward">Checkout</IonButton>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CartView;
