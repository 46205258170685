import { IonContent, IonGrid, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonRow, IonCol, IonPage, useIonViewDidEnter, IonButton, IonIcon } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import GoogleMaps from './utils/GoogleMaps';
import './Tab1.css';
import axios from 'axios';

import { useHistory } from "react-router-dom";

import { Capacitor } from '@capacitor/core';

const Tab3: React.FC = () => {

  const [isApplePhone, setApplePhone] = useState(false);

  useEffect(() => {
    async function checkIsIOS() {
      const info = Capacitor.getPlatform();
      if (info === 'ios') {
        setApplePhone(true);
      } else {
        setApplePhone(false);
      }
    }

    checkIsIOS();
  }, []);

  const [showModal, setShowModal] = useState(false);

  useIonViewDidEnter(() => {
    const tabBar = document.getElementById('app-tab-bar');
    if (tabBar != null) {
      if (tabBar.style.opacity == '0') {
        tabBar.style.display = 'inherit';
        tabBar.style.transition = '0.1s';

        setTimeout(function () {
          tabBar.style.opacity = '1';
        }, 100);
      }
    }
  });

  let history = useHistory();

  return (
    <IonPage>
      <IonContent scrollY={true} className={`no-padding`}>
        <div className={isApplePhone == true ? "ios-wrap" : ""}>
          <div className={isApplePhone == true ? "ios-wrapper-margin" : "null"}>
          </div>
          <div className="wrapper">
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="12">
                  <h2 className="welcome-intro">Restaurant Info</h2>
                  <div className="margin-top-30">
                    <GoogleMaps />
                  </div>
                  <div className="margin-top-30">
                    <h5>Address:<br></br><a href="https://www.google.com/maps/dir//7AM+Breakfast+%26+Lunch,+2950+Bank+St,+Ottawa,+ON+K1T+1N8/@45.3472624,-75.6250662,15.55z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cce09ce514f8343:0x5eaa853868d97524!2m2!1d-75.6249438!2d45.3469423?entry=ttu" target="_blank">2950 Bank St, Ottawa, ON K1T 1N8</a></h5>
                    <h5>Phone:<br></br>{process.env.REACT_APP_TENANT_PHONE}</h5>
                    <h5>Email:<br></br>{process.env.REACT_APP_TENANT_EMAIL}</h5>
                  </div>
                  <div className="note margin-top-30">
                    <p>To report bugs/issues with this app, please email <a href="mailto:">support@{process.env.REACT_APP_TENANT_DOMAIN}</a></p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
