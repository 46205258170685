import { IonContent, IonGrid, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonRow, IonCol, IonPage, useIonViewDidEnter, IonButton, IonIcon } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './Tab1.css';
import { cart, search } from 'ionicons/icons';
import axios from 'axios';

import { CartContext } from '../context/CartContext';

import menuItems from '../config/MenuItems';

import { useHistory } from "react-router-dom";

import { Capacitor } from '@capacitor/core';

import { StatusBar, Style } from '@capacitor/status-bar';

const Tab1: React.FC = () => {

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (event: { detail: { scrollTop: any; }; }) => {
    const scrollTop = event.detail.scrollTop;
    if (scrollTop > 1) {
      setScrolled(true);
    } else {
      if (searchBarVisible != true) {
        setScrolled(false);
      }
    }
  };

  const [isApplePhone, setApplePhone] = useState(false);

  useEffect(() => {
    async function checkIsIOS() {
      const info = Capacitor.getPlatform();
      if (info === 'ios') {
        setApplePhone(true);
      } else {
        setApplePhone(false);
      }
    }

    checkIsIOS();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [searchBarText, setSearchBarText] = useState('');

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [filteredMenuData, setFilteredMenuData] = useState<MenuData[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('All Items');

  useIonViewDidEnter(() => {
    const tabBar = document.getElementById('app-tab-bar');
    if (tabBar != null) {
      if (tabBar.style.opacity == '0') {
        tabBar.style.display = 'inherit';
        tabBar.style.transition = '0.1s';

        setTimeout(function () {
          tabBar.style.opacity = '1';
        }, 100);
      }
    }
  });

  let history = useHistory();

  const [searchText, setSearchText] = useState('');

  const [currentCart, setCart] = useContext(CartContext);

  const totalQuantity = currentCart.reduce((acc: any, curr: any) => acc + curr.qty, 0)


  /* Load Menu Items */

  interface MenuData {
    item_id: number;
    item_name: string;
    item_category_id: number;
    item_description: string;
    item_price: number;
    item_img_url: string;
    item_category_name: string;
  }

  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [categories, setCategories] = useState([]);

  // Inside loadMenuItems function
  function loadMenuItems() {
    // Get User Variables
    axios.post(`https://angeliques-api.vervedev.com/api/menu`, { tenant_id: process.env.REACT_APP_TENANT_ID })
      .then(res => {
        const fetchedCategories = res.data.data.categories;
        const fetchedMenuItems = res.data.data.menu_items;

        // Sort fetched menu items by sort_id
        const sortedMenuItems = fetchedMenuItems.sort((a: { sort_id: number; }, b: { sort_id: number; }) => a.sort_id - b.sort_id);

        setCategories(fetchedCategories);
        setMenuData(sortedMenuItems);
      })
      .catch(error => {
        console.error('Error fetching menu items:', error);
        // Handle error, maybe show a message to the user
      });
  }


  // Create a dictionary to store items by category
  const itemsByCategory: { [categoryId: number]: MenuData[] } = {};

  // Group items by category
  menuData.forEach((menu) => {
    if (!itemsByCategory[menu.item_category_id]) {
      itemsByCategory[menu.item_category_id] = [];
    }
    itemsByCategory[menu.item_category_id].push(menu);
  });

  const filterMenuItems = (searchText: string) => {
    const filteredItems = menuData.filter(item =>
      item.item_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredMenuData(filteredItems);
  };

  const renderMenuItems = () => {
    const filteredItems = searchText
      ? menuData.filter(item =>
        item.item_name.toLowerCase().includes(searchText.toLowerCase())
      )
      : menuData;

    // Sort categories by sort_order property
    const sortedCategories = categories.sort((a, b) => a.sort_order - b.sort_order);

    // Create an empty object to store items by category
    const itemsByCategory: { [categoryId: number]: MenuData[] } = {};

    // Group items by category
    menuData.forEach((menu) => {
      if (!itemsByCategory[menu.item_category_id]) {
        itemsByCategory[menu.item_category_id] = [];
      }
      itemsByCategory[menu.item_category_id].push(menu);
    });

    return sortedCategories.map((category) => {
      const categoryId = category.category_id;
      const categoryName = category.category_name;
      const items = itemsByCategory[categoryId] || [];

      const itemsToDisplay = selectedCategory === 'All Items'
        ? items.filter(item => filteredItems.some(filteredItem => filteredItem.item_id === item.item_id))
        : items.filter(item =>
          item.item_category_name === selectedCategory
        ).filter(item => filteredItems.some(filteredItem => filteredItem.item_id === item.item_id));

      if (itemsToDisplay.length > 0) {
        return (
          <div className="category-section" key={categoryId}>
            <div className="wrapper"><h2 className="category-title">{categoryName}</h2></div>
            <div className="horizontal category-display">
              {itemsToDisplay
                .sort((a, b) => a.sort_order - b.sort_order) // Sort items within the category by sort_order
                .map((item) => (
                  <div
                    className="menu-item"
                    key={item.item_id}
                    style={item.item_img_url.endsWith("7am-default.jpg") ? { background: `url('${item.item_img_url}') no-repeat center center` } : { background: `linear-gradient(to right, rgba(0,0,0,0.10) 100%, transparent 40%), url('${item.item_img_url}') no-repeat center center` }}
                    onClick={() => history.push("/ViewMenuItem/" + item.item_id)}
                  >
                    <div className="menu-pricing">
                      <IonGrid class="ion-no-padding">
                        <IonRow class="ion-no-padding">
                          <IonCol class="ion-no-padding" col-size="11">
                            <h5>{item.item_name}</h5>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      }

      return null;
    });
  };

  const handleSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText);
    filterMenuItems(newSearchText);
  };

  // const extractUniqueCategoryNames = () => {
  //   const uniqueCategoryNames: string[] = [];
  //   menuData.forEach((menu) => {
  //     if (!uniqueCategoryNames.includes(menu.item_category_name)) {
  //       uniqueCategoryNames.push(menu.item_category_name);
  //     }
  //   });
  //   return uniqueCategoryNames;
  // };

  const renderCategoryNames = () => {
    if (!Array.isArray(categories)) {
      return null;
    }

    const sortedCategories = categories.sort((a, b) => a.sort_order - b.sort_order);
    const categoryNames = ['All Items', ...sortedCategories.map(category => category.category_name)];

    return categoryNames.map((categoryName, index) => (
      <div
        className={`category ${selectedCategory === categoryName ? 'active-category' : ''}`}
        key={index}
        onClick={() => setSelectedCategory(categoryName)}
      >
        <h5 className="category-text">{categoryName}</h5>
      </div>
    ));
  };


  const [searchBarVisible, setSearchBarVisible] = useState(false);

  const toggleSearchBar = () => {
    setSearchBarVisible(!searchBarVisible);
  };

  const renderSearchBar = () => {
    return (
      <div className={`search-bar ${searchBarVisible ? 'search-bar-visible' : ''}`}>
        <IonInput
          placeholder='Search...'
          value={searchText}
          onIonChange={(e: { detail: { value: any; }; }) => handleSearchTextChange(e.detail.value!)}
        />
      </div>
    );
  };

  useEffect(() => {
    loadMenuItems();
  }, []);

  useEffect(() => {
    if (searchBarVisible == true && scrolled != true) {
      setScrolled(true);
    } 
  }, [searchBarVisible]);

  return (
    <IonPage>
      <IonContent scrollY={true} className={`no-padding ${searchBarVisible ? 'search-bar-visible' : ''}`} scrollEvents={true} onIonScroll={handleScroll}>
        <div className={isApplePhone == true ? "ios-wrap" : ""}>
          <div className={isApplePhone == true ? "ios-wrapper-margin" : "null"}>
          </div>
          <div className="wrapper">
            <div className="top-bar">
              <div className={scrolled ? "fixed-top scrolled" : "fixed-top"}>
                {renderSearchBar()}
                <IonGrid class="ion-no-padding">
                  <IonRow class="ion-no-padding">
                    <IonCol size="3" class="ion-no-padding">
                      <IonCol size="12" class="left-align ion-no-padding">
                        <IonButton onClick={toggleSearchBar} class="cart-button">
                          <div className="cart-icon-wrapper">
                            <IonIcon icon={search} className="cart-icon"></IonIcon>
                          </div>
                        </IonButton>
                      </IonCol>
                    </IonCol>
                    <IonCol size="6" class="center-align ion-no-padding">
                      <img src="https://pitadelites.ca/7am-Logo.svg" alt="logo" className="restaurant-logo" />
                    </IonCol>
                    <IonCol size="3" class="right-align ion-no-padding">
                      <IonButton routerLink="/CartView/" class="cart-button">
                        <div className="cart-icon-wrapper">
                          <IonIcon icon={cart} className="cart-icon"></IonIcon>
                        </div>
                        <div className="cart-count">
                          {totalQuantity}
                        </div>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="12" className="no-padding">
                  {/* <h1 className="welcome-text">Hi there,</h1> */}
                  <h2 className="welcome-intro">Choose from our<br></br>incredible menu!</h2>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="horizontal">
            {renderCategoryNames()}
          </div>
          <div className="horizontal category-display ion-no-padding padding-bottom">
            {renderMenuItems()}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
