import { createContext, useState } from 'react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, cart, receipt } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import CheckoutView from './pages/checkout/Checkout';
import OrderConfirmationView from './pages/checkout/OrderConfirmation';

import ViewMenuItem from './pages/ViewMenuItem';
import CartView from './pages/CartView';

import { CartProvider } from './context/CartContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './pages/css/style.css';

import homeicon from './assets/img/home.svg'; 
import infoicon from './assets/img/info.svg'; 

setupIonicReact();
const App: React.FC = () => (
  <CartProvider>
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            {/* Main Routes */}
            <Route exact path="/">
              <Redirect to="/tab1" />
            </Route>
            <Route exact path="/tab1">
              <Tab1 />
            </Route>
            <Route path="/tab3">
              <Tab3 />
            </Route>
            {/* Home Page Routes */}
            <Route path="/ViewMenuItem/:id" component={ViewMenuItem} />
            {/* Cart Page Routes */}
            <Route path="/CartView" component={CartView} />
            <Route path="/Checkout" component={CheckoutView} />
            <Route path="/OrderConfirmation" component={OrderConfirmationView} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom" id="app-tab-bar">
            <IonTabButton tab="tab1" href="/tab1">
            <img src={homeicon} width="30"/>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/tab3">
              <img src={infoicon} width="30"/>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  </CartProvider>
);

export default App;
